import {Injectable} from '@angular/core';
import {FileUtils} from '../utils/file-utils';
import {Filesystem, Directory as FilesystemDirectory, Encoding as FilesystemEncoding} from '@capacitor/filesystem';
import {Capacitor} from '@capacitor/core';
import {blobToBase64} from '../utils/blob-to-base64';

@Injectable({
    providedIn: 'root'
})
export class FileStorageService {
    private readonly SKIP_LEGACY_CHECK_KEY = 'filestorage_skip_legacy_check';

    private readonly LEGACY_IMAGE_SUFFIX = '.jpg';
    private readonly IMAGE_SUFFIX = '.binary.jpg';

    constructor() {
        // Determine if migration is needed
        // By checking for legacy format stored files
        if (this.isLegacyCheckEnabled()) {
            try {
                Filesystem.readdir({
                    directory: FilesystemDirectory.Data,
                    path: ''
                }).then(result => {
                    const legacyFiles = result.files.filter(file => file.name.endsWith('.jpg') && !file.name.endsWith('.binary.jpg'));

                    if (legacyFiles.length === 0) {
                        console.info('No legacy files found, disabling migration check');
                        localStorage.setItem(this.SKIP_LEGACY_CHECK_KEY, 'true')
                    }
                })
            } catch (e) {
                console.error('Legacy check failed', e);
            }
        }
    }

    async storeImage(uuid: string, fileBlob: Blob) {
        try {
            const base64 = await blobToBase64(fileBlob);
            await Filesystem.writeFile({
                directory: FilesystemDirectory.Data,
                path: uuid + this.IMAGE_SUFFIX,
                data: base64
            });
            return true;
        } catch (e) {
            console.error('storeImage failed', e);
            throw e;
        }
    }

    async removeImage(uuid: string) {
        if (this.isLegacyCheckEnabled()) {
            // Remove legacy base64 file
            try {
                await Filesystem.deleteFile({
                    directory: FilesystemDirectory.Data,
                    path: uuid + this.LEGACY_IMAGE_SUFFIX,
                });
            } catch (e) {
                if (e instanceof Error && e.message.indexOf('File does not exist') !== -1) {
                    // Ignore file does not exist
                    return;
                }

                console.error('failed to remove legacy file', e);
                throw e;
            }
        }

        try {
            await Filesystem.deleteFile({
                directory: FilesystemDirectory.Data,
                path: uuid + this.IMAGE_SUFFIX,
            });
        } catch (e) {
            if (e instanceof Error && e.message.indexOf('File does not exist') !== -1) {
                // Ignore file does not exist
                return;
            }

            console.error('removeImage failed', e);
            throw e;
        }
    }

    async getImageUri(uuid: string): Promise<string> {
        return this.getFileUri(uuid + this.IMAGE_SUFFIX, 'image/jpeg')
    }

    async getFileUri(fileName: string, contentType: string = ''): Promise<string> {
        try {
            if (Capacitor.getPlatform() === 'web') {
                // On Web we need to read file as blob because convertFileSrc doesn't support web properly.
                return URL.createObjectURL(await this.readFileAsBlob(fileName, contentType));
            } else {
                return await Filesystem.getUri({
                    directory: FilesystemDirectory.Data,
                    path: fileName
                }).then(result => {
                    return Capacitor.convertFileSrc(result.uri);
                });
            }
        } catch (e) {
            console.error('getImageUri failed', e);
            throw e;
        }
    }

    async readImageAsBlob(uuid: string): Promise<Blob> {
        return this.readFileAsBlob(uuid + this.IMAGE_SUFFIX, 'image/jpeg')
    }

    async readFileAsBlob(fileName: string, contentType: string = ''): Promise<Blob> {
        try {
            const base64 = await Filesystem.readFile({
                directory: FilesystemDirectory.Data,
                path: fileName
            });

            return base64.data instanceof Blob
                ? base64.data
                : FileUtils.base64ToBlob(base64.data, contentType);
        } catch (e) {
            console.error('readImageAsBlob failed', e);
            throw e;
        }
    }

    async checkImageExists(uuid: string) {
        try {
            await Filesystem.stat({
                directory: FilesystemDirectory.Data,
                path: uuid + this.IMAGE_SUFFIX
            });
            return true;
        } catch {
            if (this.isLegacyCheckEnabled()) {
                console.info('attempting migration of image');
                try {
                    await this.migrateBase64ImageToBinary(uuid);
                    return true;
                } catch (e) {
                    console.error('migration failed', e);
                }
            }

            // Catch file doesn't exist error (thrown by checkFile() meaning file not found) and return false
            return false;
        }
    }

    async checkTxtFileExists(filename: string) {
        try {
            await Filesystem.stat({
                directory: FilesystemDirectory.Data,
                path: filename + '.txt'
            });
            return true;
        } catch {
            // Catch file doesn't exist error (thrown by checkFile() meaning file not found) and return false
            return false;
        }
    }

    async storeTxtFile(filename: string, fileContents: string) {
        try {
            await Filesystem.writeFile({
                directory: FilesystemDirectory.Data,
                path: filename + '.txt',
                encoding: FilesystemEncoding.UTF8,
                data: fileContents
            });
            return true;
        } catch (e) {
            console.error('storeFile failed', e);
            throw e;
        }
    }

    async removeTxtFile(filename: string) {
        try {
            await Filesystem.deleteFile({
                directory: FilesystemDirectory.Data,
                path: filename + '.txt',
            });
        } catch (e) {
            if (e instanceof Error && e.message.indexOf('File does not exist') !== -1) {
                // Ignore file does not exist
                return;
            }

            console.error('removeFile failed', e);
            throw e;
        }
    }

    async readTxtFileAsJson<T>(fileName: string): Promise<T> {
        try {
            const file = await Filesystem.readFile({
                directory: FilesystemDirectory.Data,
                path: fileName + '.txt',
                encoding: FilesystemEncoding.UTF8,
            });

            const jsonText = file.data instanceof Blob
                ? await file.data.text()
                : file.data;

            return JSON.parse(jsonText);
        } catch (e) {
            console.error('readFileAsJson failed', e);
            throw e;
        }
    }

    private async migrateBase64ImageToBinary(uuid: string) {
        // Fetch legacy file
        const result = await Filesystem.readFile({
            directory: FilesystemDirectory.Data,
            path: uuid + this.LEGACY_IMAGE_SUFFIX,
            encoding: FilesystemEncoding.UTF8
        });
        // Write to new path
        await Filesystem.writeFile({
            directory: FilesystemDirectory.Data,
            path: uuid + this.IMAGE_SUFFIX,
            data: result.data
        });
        // Delete legacy file
        await Filesystem.deleteFile({
            directory: FilesystemDirectory.Data,
            path: uuid + this.LEGACY_IMAGE_SUFFIX
        });
    }

    private isLegacyCheckEnabled() {
        return localStorage.getItem(this.SKIP_LEGACY_CHECK_KEY) !== 'true'
    }
}
