import {Inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, firstValueFrom, Subject} from 'rxjs';
import {Capacitor} from '@capacitor/core';
import {MsAuthPlugin} from '@recognizebv/capacitor-plugin-msauth';
import {LOGIN_TYPE_AZURE, LoginConfig} from '../models/login-config';
import {AuthenticationProviderService} from './authentication-provider-service-impl.service';
import {filter, timeout} from 'rxjs/operators';
import {AuthenticationProviderConfig} from '../models/authentication-provider-config';
import {Browser} from '@capacitor/browser';
import {AppInsightsService} from './app-insights/app-insights.service';
import {WorkspaceConfigService} from './workspace-config.service';

export interface TokenResponse {
    access_token: string;
    refresh_token: string;
    id_token: string;
}

export interface RequestAccessTokenPayload {
    code: string;
    state: string;
    redirect_uri: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private pendingJwtGet: Promise<string> | null = null;
    private jwtHelper: JwtHelperService = new JwtHelperService();

    readonly loggedInEmail$ = new BehaviorSubject<string | null>(null);
    // Used to notify the app that the user has logged out and data should be cleared
    readonly logout$ = new Subject<void>();

    constructor(
        @Inject('AuthenticationProviderService')
        private authenticationProviderService: AuthenticationProviderService,
        private appInsightsService: AppInsightsService,
        private workspaceConfigService: WorkspaceConfigService
    ) {
        this.loggedInEmail$.subscribe(email => this.appInsightsService.setAuthenticatedUserEmail(email));
    }

    /**
     * Ensures application is authenticated
     */
    async ensureAuthenticated(): Promise<void> {
        if (await firstValueFrom(this.workspaceConfigService.workspaceConfig$) === null) {
            throw new Error('No workspace config available');
        }

        const isAdV2 = localStorage.getItem('migratedToAzureAdv2') === 'true';
        if (!isAdV2) {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('loggedInEmail');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('idToken');
            localStorage.removeItem('authenticationProviderId');
            localStorage.removeItem('clientId');
            localStorage.removeItem('tenant');
            localStorage.removeItem('scopes');
            localStorage.removeItem('type');

            localStorage.setItem('migratedToAzureAdv2', 'true');
        }

        if (environment.useMock) {
            return; // Authentication is not necessary when using mocks
        }

        await this.getJwtToken();
    }

    /**
     * Fetches valid token from storage or handles authentication if not available
     * Uses the pendingJwtGet property to prevent concurrent token refreshes / authentication attempts
     */
    async getJwtToken(): Promise<string> {
        try {
            if (this.pendingJwtGet === null) {
                this.pendingJwtGet = this.doGetJwtToken();
            }

            return await this.pendingJwtGet;
        } catch (ex) {
            this.appInsightsService.logException(ex);
            throw ex;
        } finally {
            this.pendingJwtGet = null;
        }
    }

    async useAuthenticationToken(code: string, state: string) {
        await this.getNewIdToken({code, state, redirect_uri: await this.getRedirectUri()});
    }

    async logout(): Promise<void> {
        const config = this.getLoginConfigFromLocalStorage();
        if (config && await this.useAzureNativeAuthentication(config)) {
            try {
                await MsAuthPlugin.logout({
                    clientId: config.clientId,
                    tenant: config.tenant,
                    brokerRedirectUriRegistered: true,
                });
            } catch (ex) {
                console.error('Unable to logout plugin', ex);
            }
        }

        localStorage.removeItem('loggedInEmail');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('authenticationProviderId');
        localStorage.removeItem('clientId');
        localStorage.removeItem('tenant');
        localStorage.removeItem('scopes');
        localStorage.removeItem('type');

        this.loggedInEmail$.next(null);
        this.logout$.next();

        this.workspaceConfigService.clearWorkspaceConfig();
    }

    async getLoginUri(authenticationProviderId: number, scopes: string[]) {
        const searchParams = new URLSearchParams();
        searchParams.set('redirect_uri', await this.getRedirectUri());
        searchParams.set('response_type', 'url');
        searchParams.set('authentication_provider_id', `${authenticationProviderId}`);
        searchParams.set('scope', scopes.join(' '));

        return this.doGet<{ url: string }>(`/oauth2/redirect/app?${searchParams.toString()}`);
    }

    async startLogin(config: LoginConfig) {
        if (await this.useAzureNativeAuthentication(config)) {
            try {
                await this.fetchNativeAccessToken(config);
            } catch (error) {
                console.error(error);

                return false;
            }
        } else {
            const response = await this.getLoginUri(config.authenticationProviderId, config.scopes || ['openid', 'email', 'profile']);
            // windowName only has effect on Web and is used to make the login open in the current page
            await Browser.open({url: response.url, windowName: '_self'});
        }

        return true;
    }

    private async fetchNativeAccessToken(loginConfig: LoginConfig): Promise<string | null> {
        const result = await MsAuthPlugin.login({
            clientId: loginConfig.clientId,
            tenant: loginConfig.tenant,
            brokerRedirectUriRegistered: true,
            // Remove reserved scopes, because M$
            scopes: loginConfig.scopes?.filter(scope => {
                return !['openid', 'offline_access', 'profile', 'email'].includes(scope.toLowerCase().trim());
            })
        });

        let token;
        let loggedInEmail;
        try {
            const decodedToken = this.jwtHelper.decodeToken(result.accessToken);
            loggedInEmail = decodedToken.upn || decodedToken.preferred_username || decodedToken.email || decodedToken.sub;
            token = result.accessToken;
        } catch (ex) {
            console.warn('Unable to parse access token, fallback to idtoken', ex);

            try {
                const decodedToken = this.jwtHelper.decodeToken(result.idToken);
                loggedInEmail = decodedToken.upn || decodedToken.preferred_username || decodedToken.email || decodedToken.sub;
                token = result.idToken;
            } catch (ex2) {
                console.error('Unable to parse id token', ex2);
                throw ex2;
            }
        }

        localStorage.setItem('loggedInEmail', loggedInEmail);
        localStorage.setItem('accessToken', result.accessToken);
        localStorage.setItem('idToken', token || ''); // For azure we use the accessToken
        localStorage.setItem('authenticationProviderId', `${loginConfig.authenticationProviderId}`);
        localStorage.setItem('clientId', loginConfig.clientId);
        localStorage.setItem('tenant', loginConfig.tenant || '');
        localStorage.setItem('scopes', loginConfig?.scopes?.join(',') || '');
        localStorage.setItem('type', loginConfig.type);

        this.loggedInEmail$.next(loggedInEmail);

        this.appInsightsService.logEvent('Successful login (native)', {
            timestamp: new Date().getTime() / 1000
        });

        return token || null;
    }

    private async getRedirectUri() {
        const config = await firstValueFrom(this.workspaceConfigService.workspaceConfig$);
        if (config === null) {
            throw new Error('No workspace config available');
        }

        return Capacitor.isNativePlatform()
            ? `${config.apiHost}/authenticate`
            : `${window.location.protocol}//${window.location.host}/authenticate`;
    }

    private async doGetJwtToken(): Promise<string> {
        return this.getStoredIdToken()
            || await this.getRefreshedIdToken()
            || Promise.reject('Unauthenticated');
    }

    private getStoredIdToken(): string | null {
        const idToken = localStorage.getItem('idToken');
        const loggedInEmail = localStorage.getItem('loggedInEmail');

        if (idToken !== null && !this.jwtHelper.isTokenExpired(idToken, 60) && loggedInEmail) {
            this.loggedInEmail$.next(loggedInEmail);

            return idToken;
        }

        return null;
    }

    private async getRefreshedIdToken(): Promise<string | null> {
        const config = this.getLoginConfigFromLocalStorage();
        if (!config) {
            return null;
        }

        if (await this.useAzureNativeAuthentication(config)) {
            if (!!localStorage.getItem('accessToken') && config) {
                return this.fetchNativeAccessToken(config);
            } else {
                return null;
            }
        }

        try {
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken !== null) {
                const refreshResponse = await this.requestRefreshToken(refreshToken, config.authenticationProviderId);
                const userInfo = this.jwtHelper.decodeToken(refreshResponse.id_token);
                const loggedInEmail = userInfo.email || userInfo.sub;
                localStorage.setItem('refreshToken', refreshResponse.refresh_token);
                localStorage.setItem('accessToken', refreshResponse.access_token);
                localStorage.setItem('idToken', refreshResponse.id_token);
                localStorage.setItem('loggedInEmail', loggedInEmail);
                this.loggedInEmail$.next(loggedInEmail);

                return refreshResponse.id_token;
            }
        } catch (ex) {
            this.appInsightsService.logException(ex);
            console.warn('Refresh failed', ex);
            this.loggedInEmail$.next(null);
        }

        return null;
    }

    private async getNewIdToken(payload: RequestAccessTokenPayload): Promise<string | null> {
        try {
            const searchParams = new URLSearchParams(payload.state);
            const authProviders = await firstValueFrom(this.authenticationProviderService.authenticationProvider$.pipe(
                filter((methods): methods is AuthenticationProviderConfig[] => methods !== null && methods.length > 0),
                timeout(10 * 1000)
            ));
            const searchParamsId = searchParams.get('id');
            const searchParamsIdNumber = searchParamsId ? +searchParamsId : null;
            const currentAuthProvider = authProviders.find(it => searchParamsIdNumber === it.id);

            const tokenResponse = await this.requestToken(payload, currentAuthProvider);

            const userInfo = this.jwtHelper.decodeToken(tokenResponse.id_token);
            const loggedInEmail = userInfo.email || userInfo.sub;

            localStorage.setItem('loggedInEmail', loggedInEmail);
            localStorage.setItem('refreshToken', tokenResponse.refresh_token);
            localStorage.setItem('accessToken', tokenResponse.access_token);
            localStorage.setItem('idToken', tokenResponse.id_token);

            if (currentAuthProvider) {
                localStorage.setItem('authenticationProviderId', `${currentAuthProvider.id}`);
                localStorage.setItem('clientId', currentAuthProvider.clientId);
                localStorage.setItem('tenant', currentAuthProvider.tenant || '');
                localStorage.setItem('scopes', currentAuthProvider.scopes.join(','));
                localStorage.setItem('type', currentAuthProvider.type);
            }

            this.loggedInEmail$.next(loggedInEmail);

            this.appInsightsService.logEvent('Successful login', {
                timestamp: new Date().getTime() / 1000
            });

            return tokenResponse.id_token;
        } catch (ex) {
            this.appInsightsService.logException(ex);
            console.error('Failed to authenticate', ex);
            this.loggedInEmail$.next(null);
        }

        return null;
    }

    private requestToken(auth: RequestAccessTokenPayload, config: AuthenticationProviderConfig | undefined): Promise<TokenResponse> {
        const url = !config ? '/oauth2/token/app' : `/oauth2/token/app?authentication_provider_id=${encodeURIComponent(config.id)}`;

        return this.doPost({...auth}, url, true);
    }

    private requestRefreshToken(refreshToken: string, authenticationProviderId: number): Promise<TokenResponse> {
        return this.doPost({refresh_token: refreshToken}, `/oauth2/refresh?authentication_provider_id=${encodeURIComponent(authenticationProviderId)}`);
    }

    private async getApiHostOrFail(): Promise<string> {
        const config = await firstValueFrom(this.workspaceConfigService.workspaceConfig$);
        if (config === null) {
            throw new Error('No workspace config available');
        }

        return config.apiHost;
    }

    private async doGet<T>(url: string): Promise<T> {
        const appInsightService = this.appInsightsService;
        const apiHost = await this.getApiHostOrFail();
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.withCredentials = true;
            xhr.open('GET', apiHost + url);
            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.responseText));
                } else {
                    appInsightService.logEvent('doGet failed in Authentication service', {
                        extra: xhr,
                        timestamp: new Date().getTime() / 1000
                    });
                    console.error(xhr.statusText);
                    reject(xhr.statusText);
                }
            };
            xhr.send();
        });
    }

    private async doPost<T>(parameters: {[key: string]: string | number | boolean}, url: string, withCredentials = false): Promise<T> {
        const appInsightService = this.appInsightsService;
        const apiHost = await this.getApiHostOrFail();
        return new Promise((resolve, reject) => {
            const queryString = Object.keys(parameters).map(k => {
                return encodeURIComponent(k) + '=' + encodeURIComponent(parameters[k]);
            }).join('&');
            const xhr = new XMLHttpRequest();
            xhr.withCredentials = withCredentials;
            xhr.open('POST', apiHost + url);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve(JSON.parse(xhr.responseText));
                } else {
                    appInsightService.logEvent('doPost failed in Authentication service', {
                        extra: xhr,
                        timestamp: new Date().getTime() / 1000
                    });
                    console.error(xhr.statusText);
                    reject(xhr.statusText);
                }
            };
            xhr.send(queryString);
        });
    }

    private getLoginConfigFromLocalStorage(): LoginConfig | null {
        const authenticationProviderId = localStorage.getItem('authenticationProviderId');
        const clientId = localStorage.getItem('clientId');
        const tenant: string = localStorage.getItem('tenant') || '';
        const scopesAsString = localStorage.getItem('scopes');
        const type = localStorage.getItem('type');

        if (clientId && scopesAsString && type && authenticationProviderId) {
            const scopes = scopesAsString.split(',');

            return {
                authenticationProviderId: +authenticationProviderId,
                clientId,
                tenant,
                scopes,
                type
            };
        }

        return null;
    }

    async useAzureNativeAuthentication(config: LoginConfig) {
        return config.type === LOGIN_TYPE_AZURE && Capacitor.getPlatform() === 'ios'
    }
}
