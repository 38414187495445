export class FileUtils {

    /**
     * Check if the url is a data URI
     * @param url the url to check
     * @return boolean true if it is a data url
     */
    static isDataUri(url: string) {
        return typeof url === 'string' && url.indexOf('data:') === 0;
    }

    /**
     * Check if the url is a blob URI
     * @param url the url to check
     * @return boolean true if it is a blob url
     */
    static isBlobUri(url: string) {
        return typeof url === 'string' && url.indexOf('blob:') === 0;
    }

    /**
     * Turns a data URI into a blob URI
     * @param dataURI the data uri to convert
     * @return blobURI the resource url
     */
    static dataURIToBlobURI(dataURI: string) {
        const splitData = dataURI.split(',');
        const b64Data = splitData[1];
        const mimeString = splitData[0].split(':')[1].split(';')[0];
        const blob = FileUtils.base64ToBlob(b64Data, mimeString);
        return URL.createObjectURL(blob);
    }

    /**
     * Turn base 64 image into a blob, so we can send it using multipart/form-data posts
     * @param b64Data image data
     * @param contentType image content type
     * @param sliceSize slice size
     * @return given image data as {Blob}
     */
    static base64ToBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    static downloadBlobAsFile(blob: Blob, filename: string) {
        // Blob download link
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a') as HTMLAnchorElement;

        a.href = url;
        a.download = filename;

        // Add link to DOM as firefox won't allow clicks on elements not in the DOM.
        a.style.display = 'none';
        document.body.appendChild(a);

        a.click();

        setTimeout(() => {
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }, 100);
    }

}
