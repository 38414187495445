export interface LoginConfig {
    authenticationProviderId: number;
    clientId: string;
    tenant?: string;
    scopes?: string[];
    type: string;
}

export const LOGIN_TYPE_AZURE  = 'azure';
export const LOGIN_TYPE_OPENID = 'openid';
