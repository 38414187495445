// 'secuurio-tenant' is the placeholder for the tenant name, on web build this will be replaced by the tenant name
// On app builds this is not replaced and should make the app ask for the tenant name via the workspace select page
const tenantName = 'TENANT_NAME' in window ? window['TENANT_NAME'] : 'secuurio-tenant';

export const environment = {
    production: true,
    useMock: false,
    apiHost: tenantName !== 'secuurio-tenant' ? `https://${tenantName}.check.secuur.io` : undefined,
    imageOptions: {
        annotationVersion: 1,
        imageWidth: 1600,
        imageHeight: 1200,
    }
};
