import {Injectable} from '@angular/core';
import {ApplicationInsights, ICustomProperties, ITelemetryPlugin, Tags} from '@microsoft/applicationinsights-web';
import {AngularPlugin} from '@microsoft/applicationinsights-angularplugin-js';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {WorkspaceConfigService} from '../workspace-config.service';
import {firstValueFrom} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {WorkspaceConfig} from '../../models/workspace-config';

@Injectable({
    providedIn: 'root'
})
export class AppInsightsService {
    private initialized = false;
    private appInsights: ApplicationInsights | undefined;

    private tenant: string | null = null;

    constructor(
        private router: Router,
        private workspaceConfigService: WorkspaceConfigService,
    ) {
    }

    async initialize() {
        if (this.initialized) {
            // Already initialized
            return;
        }

        try {
            this.initialized = true;

            if (environment.useMock) {
                console.info('Disabling Application insights, mock is enabled');
                return;
            }

            // Wait for workspace config to be set before initializing
            let delayedInitWarningShown = false;
            const apiHost = (await firstValueFrom(
                this.workspaceConfigService.workspaceConfig$.pipe(
                    tap(config => {
                        if (!config && !delayedInitWarningShown) {
                            delayedInitWarningShown = true
                            console.warn('Workspace config not set, delaying Application insights initialization');
                        }
                    }),
                    filter((config): config is WorkspaceConfig => config !== null),
                )
            ))?.apiHost;

            const {connectionString, tenant} = await this.fetchAIConfig(apiHost);

            if (!connectionString) {
                console.warn('Disabling Application insights, connection string not set');
                return;
            }

            const apiHostDomain = new URL(apiHost).host;
            const angularPlugin = new AngularPlugin();
            this.appInsights = new ApplicationInsights({
                config: {
                    connectionString: connectionString,

                    enableCorsCorrelation: true,
                    correlationHeaderDomains: [apiHostDomain],

                    extensions: [angularPlugin as unknown as ITelemetryPlugin],
                    extensionConfig: {
                        [angularPlugin.identifier]: {router: this.router}
                    }
                }
            });

            this.appInsights.addTelemetryInitializer(item => {
                if (item.tags) {
                    item.tags['ai.cloud.role'] = 'admin';
                } else {
                    item.tags = {
                        'ai.cloud.role': 'app'
                    } as unknown as Tags & Tags[]; // Cast to work around AI typings bug
                }

                if (this.tenant) {
                    if (item.data) {
                        item.data['tenant'] = tenant;
                    } else {
                        item.data = {
                            'tenant': tenant
                        };
                    }
                }
            });

            this.appInsights.loadAppInsights();

            this.workspaceConfigService.workspaceConfig$.subscribe(workspaceConfig => {
                if (workspaceConfig && this.appInsights) {
                    this.appInsights.config.correlationHeaderDomains = [workspaceConfig.apiHost];
                    this.fetchAIConfig(workspaceConfig.apiHost).then(({tenant, connectionString}) => {
                        this.tenant = tenant;
                        if (connectionString !== null && this.appInsights) {
                            this.appInsights.config.connectionString = connectionString;
                        }
                    })
                }
            })

            console.info('Application insights initialized');
        } catch (e) {
            console.error('Failed to initialize Application insights', e);
            this.initialized = false;
        }
    }

    logPageView(name?: string, uri?: string) {
        if (this.appInsights) {
            this.appInsights.trackPageView({
                name,
                uri
            });
        }
    }

    logEvent(name: string, properties?: ICustomProperties) {
        if (this.appInsights) {
            this.appInsights.trackEvent({name}, properties);
        }
    }

    logMetric(name: string, average: number, properties?: ICustomProperties) {
        if (this.appInsights) {
            this.appInsights.trackMetric({name, average}, properties);
        }
    }

    logException(exception: unknown, severityLevel?: number) {
        if (this.appInsights && exception instanceof Error) {
            this.appInsights.trackException({exception, severityLevel});
        } else {
            console.error(exception);
        }
    }

    logTrace(message: string, properties?: ICustomProperties) {
        if (this.appInsights) {
            this.appInsights.trackTrace({message}, properties);
        }
    }

    trackEvent(
        name: string,
        duration: number,
        properties?: {[key: string]: unknown}
    ) {
        if (this.appInsights) {
            if(!properties) {
                properties = {};
            }
            properties.duration = duration.toString();

            this.appInsights.trackEvent({
                name: name,
                properties: properties
            })
        }
    }

    setAuthenticatedUserEmail(email: string | null) {
        if (this.appInsights) {
            if (email) {
                this.appInsights?.setAuthenticatedUserContext(email)
            } else {
                this.appInsights?.clearAuthenticatedUserContext()
            }
        }
    }

    private async fetchAIConfig(apiHost: string): Promise<{
        connectionString: string | null;
        tenant: string | null;
    }> {
        return await fetch(`${apiHost}/.well-known/ai-connection-string`)
            .then(response => response.json())
            .then(response => {
                localStorage.setItem('ai-connection-string', response.connectionString);
                localStorage.setItem('ai-tenant', response.tenant);
                return response;
            })
            .catch(err => {
                console.warn('Failed to fetch AI connection string, using cached config', err);
                return {
                    connectionString: localStorage.getItem('ai-connection-string'),
                    tenant: localStorage.getItem('ai-tenant')
                };
            })
    }
}
