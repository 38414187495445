export interface WorkspaceConfig {
    apiHost: string;
    branding: BrandingConfig;
    features: Record<string, number | string | boolean>
}

/**
 * all properties are defined as optional because they could be missing if the server is outdated
 */
export type WorkspaceConfigApiResponse = Partial<WorkspaceConfig>

export interface BrandingConfig {
    colorPrimary: string;
    colorPrimaryLight: string;
    colorText: string;
    colorIcon: string;
    colorSuccess: string;
    colorWarning: string;
    colorError: string;
    colorNeutral10: string;
    colorNeutral20: string;
    colorNeutral30: string;
    colorNeutral40: string;
    colorNeutral50: string;
}

export const DefaultBrandingConfig: BrandingConfig = {
    colorPrimary: '255 166 0',
    colorPrimaryLight: '255 199 96',
    colorText: '29 34 52',
    colorIcon: '154 165 181',
    colorSuccess: '54 179 122',
    colorWarning: '255 171 0',
    colorError: '255 86 64',
    colorNeutral10: '246 246 247',
    colorNeutral20: '228 228 230',
    colorNeutral30: '192 193 198',
    colorNeutral40: '173 175 182',
    colorNeutral50: '119 122 133',
}
