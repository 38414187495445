import {Injectable} from '@angular/core';
import {DefaultBrandingConfig, WorkspaceConfig, WorkspaceConfigApiResponse} from '../models/workspace-config';
import {BehaviorSubject, Observable, switchMap} from 'rxjs';
import {environment} from '../../environments/environment';
import {distinctUntilChanged, filter, map, shareReplay} from 'rxjs/operators';
import {blobToBase64} from '../utils/blob-to-base64';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {FileStorageService} from './file-storage.service';

@Injectable({providedIn: 'root'})
export class WorkspaceConfigService {
    private workspaceConfigSubject = new BehaviorSubject<WorkspaceConfig | 'PENDING' | null>(this.loadWorkspaceConfig());
    readonly workspaceConfig$: Observable<WorkspaceConfig | null> = this.workspaceConfigSubject.pipe(
        // Filter out PENDING state, this is only used internally to signal that we are fetching the config from the server
        filter((it): it is WorkspaceConfig | null => it !== 'PENDING')
    );

    private workspaceLogoFileNameSubject = new BehaviorSubject<string | null>(
        localStorage.getItem('workspaceLogoFileName') || null
    );
    readonly workspaceLogoUri$ = this.workspaceLogoFileNameSubject.pipe(
        switchMap(async workspaceLogoFileName => {
            if (workspaceLogoFileName !== null) {
                try {
                    return await this.fileStorageService.getFileUri(
                        workspaceLogoFileName,
                        localStorage.getItem('workspaceLogoContentType') || this.getContentType(workspaceLogoFileName)
                    )
                } catch (e) {
                    console.error('failed to get uri for workspace logo', e);
                }
            }

            // Fallback to default logo
            return '/assets/logo.svg'
        }),
        shareReplay(1)
    );

    constructor(
        private fileStorageService: FileStorageService,
    ) {
        this.setupBrandingStyleElement();

        // Check for logo update on startup
        const workspaceConfigSubjectVal = this.workspaceConfigSubject.value;
        if (workspaceConfigSubjectVal !== null && workspaceConfigSubjectVal !== 'PENDING' && !environment.useMock) {
            this.updateWorkspaceConfig(workspaceConfigSubjectVal.apiHost).catch(e => {
                console.warn('Failed to refresh workspace config', e);
            });
        }
    }

    async setWorkspaceConfig(workspaceName: string): Promise<void> {
        let apiHost = `https://${workspaceName}.check.secuur.io`;
        if (environment.production === false && window.location.hostname === 'localhost' && workspaceName === 'localhost') {
            // Support connection to local development server
            apiHost = 'http://localhost:8080';
        }

        await this.updateWorkspaceConfig(apiHost);
    }

    clearWorkspaceConfig(): void {
        // Don't clear workspace for tenant specific apps, instead reset to default
        if (environment.apiHost) {
            this.updateWorkspaceConfig(environment.apiHost).catch(e => {
                console.warn('Failed to fetch workspace config from environment apiHost', e);
            })

            return;
        }

        localStorage.removeItem('workspaceConfig');
        this.workspaceConfigSubject.next(null);
    }

    isFeatureEnabled(featureName: string): Observable<boolean> {
        return this.workspaceConfig$.pipe(
            map(workspaceConfig => {
                if (workspaceConfig === null) {
                    return false;
                }

                return workspaceConfig.features[featureName] === true;
            })
        );
    }

    getConfiguredValue(key: string): Observable<number | string | boolean | null> {
        return this.workspaceConfig$.pipe(
            map(workspaceConfig => {
                if (workspaceConfig === null) {
                    return null;
                }

                return workspaceConfig.features[key] ?? null;
            })
        );
    }

    /**
     * IMPORTANT: This function assumes '-' means the value should be empty string
     * @param key
     * @param defaultValue
     */
    getTextSetting(key: string, defaultValue: string): Observable<string> {
        return this.getConfiguredValue(key).pipe(
            map(value => {
                if (value === null) {
                    return defaultValue;
                } else if (value === '-') {
                    return '';
                } else {
                    return value.toString().trim();
                }
            })
        );
    }

    private async updateWorkspaceConfig(apiHost: string): Promise<void> {
        const workspaceConfig = await this.fetchWorkspaceConfig(apiHost);

        // Workspace is valid, download logo
        await this.downloadWorkspaceLogo(workspaceConfig);

        if (JSON.stringify(workspaceConfig) === JSON.stringify(this.workspaceConfigSubject.value)) {
            // No change, skip update
            return;
        }

        localStorage.setItem('workspaceConfig', JSON.stringify(workspaceConfig));
        this.workspaceConfigSubject.next(workspaceConfig);
    }

    private async fetchWorkspaceConfig(apiHost: string): Promise<WorkspaceConfig> {
        const response: WorkspaceConfigApiResponse = await fetch(
            `${apiHost}/.well-known/workspace/config.json`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            }).then(it => {
                if (!it.ok) {
                    throw new Error(`Failed to fetch workspace config, status code ${it.status}`);
                }

                return it.json();
        });

        if (response !== null
            && typeof response === 'object'
            && 'apiHost' in response && typeof response.apiHost === 'string'
        ) {
            return {
                apiHost: response.apiHost,
                branding: {
                    ...DefaultBrandingConfig,
                    ...response.branding
                },
                features: {
                    ...response.features,
                }
            };
        }

        throw new Error('Invalid workspace name');
    }

    private async downloadWorkspaceLogo(workspaceConfig: WorkspaceConfig): Promise<void> {
        const cachedApiHost = localStorage.getItem('workspaceLogoLastApiHost');
        const cachedLastModified = cachedApiHost !== workspaceConfig.apiHost
            ? null // Different workspace, force re-download by not sending If-Modified-Since header
            : localStorage.getItem('workspaceLogoLastModified');


        const response = await fetch(`${workspaceConfig.apiHost}/.well-known/workspace/logo`, {
            method: 'GET',
            headers: cachedLastModified !== null ? {
                'If-Modified-Since': cachedLastModified,
            } : undefined
        });

        if (response.status === 304) {
            // Not modified
            return;
        } else if (response.status === 200) {
            const contentType = response.headers.get('Content-Type');
            if (contentType === null) {
                console.error('Missing Content-Type header');
                return;
            }

            const newFileName = 'logo' + this.getExtension(contentType);

            await Filesystem.writeFile({
                directory: Directory.Data,
                path: newFileName,
                data: await blobToBase64(await response.blob())
            });

            localStorage.setItem('workspaceLogoLastApiHost', workspaceConfig.apiHost);
            localStorage.setItem('workspaceLogoFileName', newFileName);
            localStorage.setItem('workspaceLogoContentType', contentType);
            this.workspaceLogoFileNameSubject.next(newFileName);
        } else {
            console.error(`Failed to download workspace logo, status code ${response.status}`, {responseBody: await response.text()});
        }
    }

    private getExtension(contentType: string) {
        if (contentType === 'image/svg+xml') {
            return '.svg'
        } else if (contentType === 'image/png') {
            return '.png'
        } else if (contentType === 'image/jpeg') {
            return '.jpg'
        } else {
            throw new Error(`Unsupported logo content type ${contentType}`)
        }
    }

    private getContentType(filename: string) {
        if (filename.endsWith('.svg')) {
            return 'image/svg+xml'
        } else if (filename.endsWith('.png')) {
            return 'image/png'
        } else {
            return '.jpg'
        }
    }

    private setupBrandingStyleElement() {
        const styleElement = document.createElement('style');
        styleElement.id = 'brandingStyle';
        document.head.appendChild(styleElement);

        // Setup branding style block
        this.workspaceConfig$.pipe(
            map(workspaceConfig => {
                if (workspaceConfig === null) {
                    return null;
                }
                // language=CSS
                return `:root, :host, ::before, ::after {
                    --color-primary: ${workspaceConfig.branding.colorPrimary};
                    --color-primary-light: ${workspaceConfig.branding.colorPrimaryLight};
                    --color-text: ${workspaceConfig.branding.colorText};
                    --color-icon: ${workspaceConfig.branding.colorIcon};
                    --color-success: ${workspaceConfig.branding.colorSuccess};
                    --color-warning: ${workspaceConfig.branding.colorWarning};
                    --color-error: ${workspaceConfig.branding.colorError};
                    --color-neutral-10: ${workspaceConfig.branding.colorNeutral10};
                    --color-neutral-20: ${workspaceConfig.branding.colorNeutral20};
                    --color-neutral-30: ${workspaceConfig.branding.colorNeutral30};
                    --color-neutral-40: ${workspaceConfig.branding.colorNeutral40};
                    --color-neutral-50: ${workspaceConfig.branding.colorNeutral50};
                }`;
            }),
            // Only update style block when branding changes
            distinctUntilChanged()
        ).subscribe(brandingCss => {
            styleElement.textContent = brandingCss;
        });
    }

    private loadWorkspaceConfig(): WorkspaceConfig | 'PENDING' | null {
        // When we add more properties make sure to account for those missing in the localstorage
        const cachedConfig = localStorage.getItem('workspaceConfig');
        if (cachedConfig) {
            const parsedConfig: Partial<WorkspaceConfig> = JSON.parse(cachedConfig);
            if (parsedConfig.apiHost) {
                return {
                    apiHost: parsedConfig.apiHost,
                    branding: parsedConfig.branding || DefaultBrandingConfig,
                    features: parsedConfig.features || {},
                };
            }
        }

        // E2E Mocked workspace config
        const mockEnv = environment as {workspaceConfig?: WorkspaceConfig }
        if ('workspaceConfig' in mockEnv) {
            return mockEnv.workspaceConfig as WorkspaceConfig;
        }

        // If environment.apiHost is set, use that as default to fetch the workspace config
        if (environment.apiHost) {
            this.updateWorkspaceConfig(environment.apiHost).catch(e => {
                console.warn('Failed to fetch workspace config from environment apiHost', e);
            })

            return 'PENDING';
        }

        return null;
    }
}
